import React from 'react'
import { object, string } from 'prop-types'
import { Typography, Box, styled } from '@mui/material'
import '../../assets/css/components/order/delivery.sass'
import { splitDates } from '@helpers/checkout/delivery-section'
import { renderDoorwayDelivery, getDeliveryLabel } from '@helpers/delivery'
import { getDateFull } from '@helpers/date'
import RTGLink from '@shared/link'
import SplitDeliverySummary from '@components/shared/split-delivery-summary-v2'
import { VendorShippingMessage } from '@components/checkout/checkout-parts/delivery-section/delivery-section-non-rtg-option'
import EstimatedDeliveryMessage from '@components/checkout/checkout-parts/delivery-section/estimated-delivery-message'
import DeliveryProduct from '../checkout/checkout-parts/delivery-section/delivery-product-v2'
import DistributionCenterPopup from '../checkout/checkout-parts/delivery-section/distribution-center-popup'
import DeliveryTile from '../../@rtg2022/components/molecules/DeliveryTile/delivery-tile'

const Summary = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  width: '100%',
})
const DeliveryContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    '& > div > div > div': {
      margin: '15px 0',
    },
    h4: {
      margin: 0,
    },
  },
}))

const SummaryGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  textAlign: 'left',
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
}))

const StyledList = styled('ul')(({ theme }) => ({
  listStyle: 'inside',
  maxWidth: '45%',
  fontSize: '14px',
  'li:last-of-type': {
    marginBottom: '10px',
  },
  a: {
    color: theme.palette.primary.main,
    fontSize: '14px',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
  },
}))

const DeliveryProductCart = styled(DeliveryProduct)(() => ({
  padding: '15px 0',
  '&:not(:last-child)': {
    borderBottom: `1px solid rgba(0, 83, 160, 0.12)`,
  },
}))

const DeliveryTitle = styled(Typography)({
  fontSize: '18px',
  fontWeight: 500,
})

export const generateAutomatedMessage = (calendarType = '', isDoorwayDelivery, isExpressDelivery) => {
  const msg = `You will be notified of the estimated delivery window ${
    isExpressDelivery ? '' : '2 days '
  }prior to delivery.`

  if (isDoorwayDelivery) {
    return (
      <StyledList>
        <li data-testId="delivery-notification-message">{msg}</li>
        <li data-testId="signature-required-message">Signature required upon delivery.</li>
        <li data-testId="assembly-message">Does NOT include professional assembly or removal of packaging.</li>
        By selecting Doorway Delivery, you agree that you are solely responsible for assembly as set forth in the&nbsp;
        <RTGLink
          action="click"
          style={{ lineHeight: '0.7rem' }}
          data={{
            url: 'https://misc.rtg-prod.com/rtgcom-terms-of-sale.pdf',
            title: 'Rooms To Go Terms of Use',
            action: 'click',
            target: '_blank',
          }}
        >
          Online Terms and Conditions of Sale, Limited Product Warranty and Dispute Resolution/Arbitration Agreement
        </RTGLink>
        .
      </StyledList>
    )
  }

  return calendarType === 'daily' ? msg : null
}

const getOrderStatusUrl = ord => {
  let url = '/order/status'
  const order = ord?.orderCreationResponse?.createorderresult?.orders?.find(i => i.deliverytype === 'D')
  if (order && order?.ordernumber) {
    url += `?ordernumber=${order?.ordernumber}`
    if (ord?.contact?.phone) url += `&phone=${ord?.contact?.phone}`
    if (ord?.shippingAddress?.zip) url += `&zip=${ord?.shippingAddress?.zip}`
  }

  return url
}

const OrderDelivery = ({ order, className }) => {
  const {
    calendarType,
    defaultDelivery,
    doorwayDelivery,
    isPickup,
    otherDeliveryItems = [],
    aggDeliveryItems = [],
    splitDeliveryDates = null,
    aggUpsDeliveryItems,
    aggUspsDeliveryItems,
    aggVendorDeliveryItems,
  } = order || {}

  const allProduct = [...aggDeliveryItems, ...otherDeliveryItems]

  const deliveryDate = getDateFull(order.deliveryDate)
  const estimatedDeliveryRange = defaultDelivery ? splitDates(order.deliveryDate).splitDatesRange : null
  const hasDeliveryItems =
    aggDeliveryItems.length > 0 ||
    aggUpsDeliveryItems.length > 0 ||
    aggUspsDeliveryItems.length > 0 ||
    aggVendorDeliveryItems.length > 0

  const isEstimatedDelivery = calendarType === 'weekly'
  const orderStatusUrl = isEstimatedDelivery ? getOrderStatusUrl(order) : null

  return (
    <DeliveryContainer className={className}>
      {hasDeliveryItems && <DeliveryTitle>Delivery</DeliveryTitle>}
      {aggDeliveryItems?.length > 0 && splitDeliveryDates && <SplitDeliverySummary order={order} />}
      {/* PICKUP ITEMS */}
      {isPickup && (
        <>
          <DeliveryTitle>Pickup</DeliveryTitle>
          {!splitDeliveryDates && (
            <>
              <Summary>
                <SummaryGroup>
                  <DeliveryTile
                    subheaderComponent={() => (
                      <Typography>
                        Pick-up from <DistributionCenterPopup order={order} /> on: {deliveryDate}
                      </Typography>
                    )}
                  >
                    {allProduct.map(item => (
                      <DeliveryProductCart
                        key={item.sku}
                        item={item}
                        warrantyEnabled={item.warrantyEnabled}
                        displayPrice={false}
                      />
                    ))}
                  </DeliveryTile>
                </SummaryGroup>
              </Summary>
            </>
          )}
        </>
      )}
      {/* DELIVERY ITEMS */}
      {!isPickup && aggDeliveryItems?.length > 0 && !splitDeliveryDates && (
        <Summary>
          <SummaryGroup>
            <DeliveryTile
              title={defaultDelivery ? estimatedDeliveryRange : deliveryDate}
              subheaderComponent={() => renderDoorwayDelivery(order, doorwayDelivery)}
              isEstimatedDelivery
            >
              {aggDeliveryItems.map(item => (
                <DeliveryProductCart
                  key={item.sku}
                  item={item}
                  warrantyEnabled={item.warrantyEnabled}
                  displayPrice={false}
                />
              ))}
            </DeliveryTile>
            {isEstimatedDelivery && (
              <EstimatedDeliveryMessage
                deliveryDate={getDateFull(order.deliveryDate, true)}
                isConfirmationPage
                orderStatusUrl={orderStatusUrl}
              />
            )}
          </SummaryGroup>
          <Box mb={{ xs: 1, md: 2 }} data-testId={!doorwayDelivery && 'delivery-notification-message'}>
            {generateAutomatedMessage(calendarType, doorwayDelivery, order.isExpress)}
          </Box>
        </Summary>
      )}
      {aggVendorDeliveryItems.length > 0 && (
        <Summary>
          <SummaryGroup>
            <DeliveryTile
              title={getDeliveryLabel(aggVendorDeliveryItems?.[0]?.deliveryType, doorwayDelivery)}
              subheaderComponent={() => renderDoorwayDelivery(order, true, true)}
            >
              {aggVendorDeliveryItems.map(item => (
                <DeliveryProductCart
                  key={item.sku}
                  item={item}
                  warrantyEnabled={item.warrantyEnabled}
                  displayPrice={false}
                />
              ))}
            </DeliveryTile>
            <VendorShippingMessage
              deliveryDate={order.deliveryDate}
              isConfirmationPage
              isStoreOrder={!!order.storeOrder}
            />
          </SummaryGroup>
        </Summary>
      )}
      {!isPickup && aggUpsDeliveryItems.length > 0 && (
        <Summary>
          <SummaryGroup>
            <DeliveryTile
              title={getDeliveryLabel(aggUpsDeliveryItems?.[0]?.deliveryType, doorwayDelivery)}
              subheaderComponent={() => renderDoorwayDelivery(order, true, true)}
            >
              {aggUpsDeliveryItems.map(item => (
                <DeliveryProductCart
                  key={item.sku}
                  item={item}
                  warrantyEnabled={item.warrantyEnabled}
                  displayPrice={false}
                />
              ))}
            </DeliveryTile>
            <VendorShippingMessage
              deliveryDate={order.deliveryDate}
              isConfirmationPage
              isStoreOrder={!!order.storeOrder}
            />
          </SummaryGroup>
        </Summary>
      )}
      {!isPickup && aggUspsDeliveryItems.length > 0 && (
        <Summary>
          <SummaryGroup>
            <DeliveryTile
              title={getDeliveryLabel(aggUspsDeliveryItems?.[0]?.deliveryType, doorwayDelivery)}
              subheaderComponent={() => renderDoorwayDelivery(order, true, true)}
            >
              {aggUspsDeliveryItems.map((item, i) => {
                const itemKey = `usps_item_${item.sku}_${i}`
                return (
                  <DeliveryProductCart
                    key={itemKey}
                    item={item}
                    warrantyEnabled={item.warrantyEnabled}
                    displayPrice={false}
                  />
                )
              })}
            </DeliveryTile>
            <VendorShippingMessage
              deliveryDate={order.deliveryDate}
              isConfirmationPage
              isStoreOrder={!!order.storeOrder}
            />
          </SummaryGroup>
        </Summary>
      )}
    </DeliveryContainer>
  )
}

OrderDelivery.propTypes = {
  order: object,
  className: string,
}

export default OrderDelivery
