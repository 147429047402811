import React, { useMemo } from 'react'
import { objectOf, any, string, bool, array } from 'prop-types'
import { styled, Box, Typography, Stack } from '@mui/material'
import { decodeHtml } from '@helpers/string-helper'
import { productUrl } from '@helpers/route'
import { getProductDisplayImage, BED_TYPES } from '@helpers/product'
import { isServiceContract } from '@helpers/extendedServiceContract'
import { useTest, TEST_ID_CATEGORIES } from '@hooks/useTest'
import RoomItemList from '@shared/room-item-list'
import RTGLink from '../../../shared/link'
import PriceAtom from '../../../../@rtg2022/components/atoms/Price/Price'

const DeliveryProductWrapper = styled(RTGLink)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  color: theme.palette.secondary.dark,
  marginBottom: '5px',
  img: {
    width: '100px',
    height: '75px',
    backgroundColor: theme.palette.secondary.light,
    objectFit: 'contain',
  },
  span: {
    fontWeight: '500',
    fontSize: '16px',
  },
}))

const ProductOrderInfo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}))

const ProductOrderDescription = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 8px',
  flex: 1,
  '& > div': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '1rem',
  },
  p: {
    fontSize: '0.75rem',
  },
}))

const WarrantyText = styled(Typography)(() => ({
  fontSize: '0.75rem',
  marginTop: '0.5rem',
}))

const ContainerItemsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '4px',
  paddingLeft: '8px',
  [theme.breakpoints.down('md')]: {
    paddingLeft: '0',
  },
}))

const ContainerItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px',
  img: {
    width: '50px!important',
    height: '50px!important',
    backgroundColor: theme.palette.secondary.light,
    objectFit: 'contain',
  },
  p: {
    fontWeight: '400',
    textTransform: 'capitalize',
    fontSize: '0.75rem',
  },
}))

const StyledRoomItemList = styled(RoomItemList)(() => ({
  paddingBottom: '14px',
  marginTop: '0!important',
  '.room-item-container': {
    padding: 0,
    gap: '14px',
    '.room-item-squared-container': {
      backgroundColor: '#F5F5F5',
      height: '70px',
      '.MuiGrid-item': {
        display: 'flex',
        flexDirection: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px',
        maxHeight: '46px',
        maxWidth: '70px',
        img: {
          height: '32px',
        },
      },
    },
  },
}))

const GIFT_CARD_SKU = '83333333'

const RoomPackage = ({ escType, items }) => (
  <Stack mt={1.5} sx={{ flexDirection: 'column !important' }}>
    <Typography style={{ fontSize: '16px' }}>Room Package:</Typography>
    {items.map(item => (
      <Stack
        key={item.sku}
        alignItems={{ xs: 'flex-start', sm: 'flex-end' }}
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 0, sm: 0.5 }}
      >
        <span style={{ fontSize: '14px', fontWeight: 400 }}>{item.title}</span>
        <Typography
          component="span"
          pl={{ xs: '4px', sm: 0 }}
          pb={{ xs: '4px', sm: 0 }}
          style={{ fontSize: '12px', fontStyle: 'italic', fontWeight: 400 }}
        >
          {item?.warrantyEnabled && !!item?.warrantyPrice ? ` - ${escType} added` : ` - No ${escType.toLowerCase()}`}
        </Typography>
      </Stack>
    ))}
  </Stack>
)

RoomPackage.propTypes = {
  escType: string,
  items: array,
}

const DeliveryProduct = ({
  children,
  className,
  item,
  noImage,
  requiredAddon,
  warrantyEnabled,
  isStoreSku,
  supressQuantity,
  displayPrice = true,
}) => {
  const { price, quantity, lineItem } = item || {}
  const product = item?.product || item
  const { generateTestId } = useTest()

  // Container Logic
  const containerSku = item?.containerSku ?? item?.lineItem?.containerSku
  const containerProperties = item?.containerProperties ?? item?.lineItem?.containerProperties
  const containerItems = containerProperties?.slots?.reduce((fillersArray, slot) => {
    fillersArray.push(...slot?.fillers)
    return fillersArray
  }, [])

  let productTitle = product?.title ?? ''
  if (containerSku) {
    productTitle = containerProperties?.name
  }

  let decodedTitle = productTitle ? decodeHtml(productTitle) : ''
  if (product?.sku === GIFT_CARD_SKU && (price || item?.unitPrice || lineItem?.unitPrice)) {
    decodedTitle += ` - $${price || item?.unitPrice || lineItem?.unitPrice}`
  }

  let imageUrl = getProductDisplayImage(product)
  if (containerSku) {
    imageUrl =
      containerProperties?.image ||
      containerProperties?.slots?.find(slot => BED_TYPES.includes(slot?.name))?.fillers[0]?.image
  }
  const needImageHeight =
    product?.sku !== GIFT_CARD_SKU && !item?.isConfigurable && !imageUrl?.endsWith('image-not-found.png')
  const escProvider =
    (item?.warrantyPrice?.provider ||
      item?.warrantyPrice?.provider_name ||
      lineItem?.warrantyPrice?.provider ||
      lineItem?.warrantyPrice?.provider_name) ??
    ''
  const isSvcCntrct = warrantyEnabled && isServiceContract(escProvider)

  const linePrice = useMemo(() => {
    if (containerSku && lineItem?.unitPrice) {
      return lineItem?.unitPrice
    }
    return price || lineItem?.unitPrice
  }, [containerSku, lineItem, price])

  const route = useMemo(() => {
    let url = product && product?.route ? product?.route : null
    if (containerSku && containerItems?.length) {
      let params = ''
      containerProperties?.slots?.forEach((slot, i) => {
        params += `"${slot?.name}":"${slot?.fillers?.[0]?.sku}"`
        if (i < containerItems?.length - 1) {
          params += ','
        }
      })
      url += `?slots={${params},"userTouched":true}`
      return url
    }
    return url
  }, [containerItems, containerProperties, containerSku, product])

  return (
    <DeliveryProductWrapper
      data-testid={generateTestId(TEST_ID_CATEGORIES.DELIVERY_PRODUCT, product?.sku)}
      className={className}
      data={{
        slug: productUrl(route),
        altDesc: productTitle,
      }}
      disabled={productUrl(route).includes('productnull') || requiredAddon || isStoreSku}
    >
      <ProductOrderInfo>
        {!noImage && (
          <>
            {!requiredAddon && product?.primary_image && (
              <Box
                data={{
                  slug: productUrl(route),
                  altDesc: productTitle,
                }}
                aria-hidden="true"
                tabIndex="-1"
              >
                <img
                  src={`${imageUrl}${needImageHeight ? '&h=75' : ''}`}
                  alt=""
                  role="presentation"
                  aria-hidden="true"
                  className="product-image"
                  style={{ backgroundColor: 'unset' }}
                />
              </Box>
            )}
          </>
        )}
        <ProductOrderDescription>
          <Box>
            <Box
              sx={{ textTransform: 'capitalize' }}
              data-testId={generateTestId(TEST_ID_CATEGORIES.DELIVERY_PRODUCT, 'title')}
            >
              {productTitle ? decodedTitle : `SKU: ${product?.sku}`}{' '}
              {!supressQuantity && <Typography className="quantity">Qt: {` ${quantity}`}</Typography>}
            </Box>
            {displayPrice && (price || lineItem?.unitPrice) && (
              <PriceAtom dataTestId={generateTestId(TEST_ID_CATEGORIES.DELIVERY_PRODUCT, 'price')} price={linePrice} />
            )}
          </Box>
          {warrantyEnabled && (
            <Box>
              <WarrantyText>{`Furniture ${isSvcCntrct ? 'Service Contract' : 'Protection Plan'} Added`}</WarrantyText>
              {displayPrice && lineItem?.warrantyPrice?.price > 0 && (
                <PriceAtom price={lineItem?.warrantyPrice?.price} />
              )}
            </Box>
          )}
          {warrantyEnabled && item?.lineItem?.isStoreSku && item?.lineItem?.childItems?.length > 0 && (
            <RoomPackage
              escType={isSvcCntrct ? 'Service Contract' : 'Protection Plan'}
              items={item.lineItem.childItems}
            />
          )}
          {children}
        </ProductOrderDescription>
      </ProductOrderInfo>
      <StyledRoomItemList
        hideBorders
        packageSkuMap={product?.sku}
        fullWidth={!displayPrice}
        items={containerItems}
        text="Room Includes"
      />
    </DeliveryProductWrapper>
  )
}

DeliveryProduct.propTypes = {
  children: any,
  className: string,
  item: objectOf(any),
  requiredAddon: bool,
  warrantyEnabled: bool,
  noImage: bool,
  isStoreSku: bool,
  displayPrice: bool,
  supressQuantity: bool,
}

export default DeliveryProduct
