import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box, styled } from '@mui/material'
import { useTest, TEST_ID_CATEGORIES } from '@hooks/useTest'

const DeliveryTileContainer = styled(Box)(({ theme }) => ({
  flex: '45%',
  maxWidth: '45%',
  padding: '15px',

  [theme.breakpoints.down('lg')]: {
    flex: '1 0 0px',
    flexDirection: 'column',
    margin: '15px',
    maxWidth: 'unset',
    padding: 0,
  },
}))

const Header = styled('h4')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '19px',
  fontWeight: 700,
  marginBottom: '15px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '15px',
    margin: '0 0 8px 13px',
  },
  button: {
    fontSize: '18px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
  },
  '& .est': {
    fontWeight: 400,
  },
}))

const SubHeader = styled(Box)(({ theme }) => ({
  '& > p': {
    color: theme.palette.primary.dark,
    fontSize: '14px',
  },
  '& > p:first-of-type': {
    fontSize: '16px',
  },
  span: {
    fontWeight: 500,
  },
}))

const SubTitleText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  color: theme.palette.primary.dark,
  textTransform: 'uppercase',
}))

const DeliveryTile = ({
  children,
  isEstimatedDelivery,
  title,
  subtitle,
  description,
  subheaderComponent = () => {},
}) => {
  const { generateTestId } = useTest()
  return (
    <DeliveryTileContainer data-testid={generateTestId(TEST_ID_CATEGORIES.DELIVERY_TITLE, 'container')}>
      <Header>
        {isEstimatedDelivery && (
          <span className="est" data-testId={generateTestId(TEST_ID_CATEGORIES.DELIVERY_TITLE, 'estimated-delivery')}>
            Est.{' '}
          </span>
        )}
        {title}
        <SubHeader>
          {subtitle && (
            <SubTitleText data-testId={generateTestId(TEST_ID_CATEGORIES.DELIVERY_TITLE, 'subtitle')}>
              {subtitle}
            </SubTitleText>
          )}
          {description && (
            <Typography data-testId={generateTestId(TEST_ID_CATEGORIES.DELIVERY_TITLE, 'description')}>
              {description}
            </Typography>
          )}
          {subheaderComponent && (
            <Typography data-testId={generateTestId(TEST_ID_CATEGORIES.DELIVERY_TITLE, 'subheader')}>
              {typeof subheaderComponent === 'function' ? subheaderComponent() : subheaderComponent}
            </Typography>
          )}
        </SubHeader>
      </Header>
      {children}
    </DeliveryTileContainer>
  )
}

DeliveryTile.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  subheaderComponent: PropTypes.any,
  isEstimatedDelivery: PropTypes.bool,
}

export default DeliveryTile
