import React, { useMemo } from 'react'
import { string, bool, number } from 'prop-types'
import useProductQuery from '@hooks/useProductQuery'
import { getImagesForProductTile, SaleFlagType } from '@helpers/product'
import { decodeTrademarkEntity } from '@helpers/string-helper'
import { analyticsProduct } from '@helpers/google-tag-manager'
import ProductTileMini from './ProductTileMini'
import { useProduct } from '../hooks'

const ProductTileMiniWrapper = ({ sku, hideTitleAndPrice = false, source, index }) => {
  const { product, isLoading, isError, inViewRef } = useProductQuery(sku, true, true)
  const { price, sale, isThankyouDiscount } = useProduct({ product })
  const productImages = getImagesForProductTile(product ?? {})
  const [primaryImage, alternativeImage] = productImages
  let saleText = ''
  if (sale) {
    saleText = product?.brand === 'tempur-pedic' ? SaleFlagType.Save : SaleFlagType.Sale
  }
  if (product.newNow) {
    saleText = SaleFlagType.NewNow
  }

  const trackingData = useMemo(() => {
    if (!product) return {}
    let sliderTitle = ''
    if (product && product.sliderTitle) sliderTitle = `${product.sliderTitle}`
    else if (source) sliderTitle = `${source.replace(/[-_]/g, ' ')}`
    return {
      category: 'product tile',
      action: 'click',
      event: 'ee_click',
      label: product.sku,
      value: index + 1,
      ecommerce: {
        click: {
          position: index,
          products: [analyticsProduct(product, 1, index)],
          list: sliderTitle ? `${sliderTitle}` : `${source && source.replace(/[-_]/g, ' ')}`,
        },
      },
    }
  }, [product, source, index])

  if (isError) return null

  return (
    <div ref={inViewRef}>
      <ProductTileMini
        title={decodeTrademarkEntity(product?.title)}
        sku={product?.sku}
        route={product?.route}
        productImage={primaryImage ?? alternativeImage}
        trackingData={trackingData}
        isSkeleton={isLoading || !product}
        isSale={sale || product?.newNow}
        saleText={saleText}
        price={price}
        hideTitleAndPrice={hideTitleAndPrice}
        isError={isError}
        isThankyouDiscount={isThankyouDiscount}
      />
    </div>
  )
}

export default React.memo(ProductTileMiniWrapper)

ProductTileMiniWrapper.propTypes = {
  sku: string.isRequired,
  hideTitleAndPrice: bool,
  index: number,
  source: string,
}
