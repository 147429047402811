import React, { useMemo } from 'react'
import { object, bool, string } from 'prop-types'
import { useTest } from '@hooks/useTest'
import { styled, Stack, Typography, Box } from '@mui/material'
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined'
import { useFirebaseContext } from '@context/firebaseContext'
import CreateNewAccountShort from '@components/accounts/CreateNewAccount/CreateNewAccountShort'
import AppDownload from './app-download'

const SuccessHeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  fontSize: '18px',
  fontWeight: 500,
}))

const CreateAccountHeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '16px',
  fontWeight: 600,
}))

const AppDownloadWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

const StyledCreateNewAccountShort = styled(CreateNewAccountShort)(({ theme }) => ({
  alignSelf: 'center',
  width: '300px',
  marginTop: '24px',
  button: { maxWidth: 'unset!important' },
  span: { color: theme.palette.secondary.lighter },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

const SuccessContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    maxHeight: 'unset',
  },
}))

export default function SuccessHeader({ contact, showAccountForm, zipcode }) {
  const { email } = useFirebaseContext()?.user ?? {}
  const { generateTestId } = useTest()

  const showCreateAccountForm = email !== contact?.email && showAccountForm

  const defaultFormValues = useMemo(
    () => ({
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      email: contact?.email,
      zipcode,
      password: '',
      confirmPassword: '',
    }),
    [contact, zipcode],
  )

  return (
    <SuccessContainer>
      <Stack spacing={1.5} pb={4}>
        <Stack direction="row" spacing={1} alignItems="center" pt={3}>
          <CheckCircleOutlined color="success" />
          <SuccessHeaderText
            data-testid={generateTestId('success-header', 'order-submitted')}
          >{`${contact?.firstName}, Your Order Has Been Submitted Successfully!`}</SuccessHeaderText>
        </Stack>

        <Typography data-testid={generateTestId('success-header', 'confirmation-email')}>
          A confirmation email will be sent to <span style={{ fontWeight: 700 }}>{contact?.email ?? 'you'}.</span>
        </Typography>
        <Typography data-testid={generateTestId('success-header', 'contact-questions')}>
          For questions or concerns regarding your order, please contact Customer Support at (888) 709-5380.
        </Typography>

        <Stack direction="row" flexWrap="wrap" justifyContent="space-evenly" className="no_print">
          {/* CREATE ACCOUNT FORM */}
          {showCreateAccountForm && (
            <Stack pt={1.5} sx={{ minWidth: '350px', maxWidth: '450px' }}>
              <CreateAccountHeaderText>Save your information for next time.</CreateAccountHeaderText>
              <Typography>
                Create an account today for a faster checkout during your next purchase, save favorites, view order
                stack and more.
              </Typography>
              <StyledCreateNewAccountShort defaultFormValues={defaultFormValues} />
            </Stack>
          )}
          <AppDownloadWrapper justifyContent="center">
            <AppDownload />
          </AppDownloadWrapper>
        </Stack>
      </Stack>
    </SuccessContainer>
  )
}

SuccessHeader.propTypes = {
  contact: object,
  showAccountForm: bool,
  zipcode: string,
}
