import { useState, useEffect, useMemo } from 'react'
import { any, string } from 'prop-types'
import { productPrice, fetchProductOrGC } from '@helpers/product'
import { getRoomsToGoDeliveryItems, getOtherDeliveryItems } from '@helpers/cart'
import { getNonRTGDeliveryItems } from '@helpers/checkout/delivery-section'
import { getOrder } from '@services/checkout'

export const FETCH_PROPERTIES = {
  FETCH_ORDER: 'FETCH_ORDER',
  FETCH_PRODUCTS_FROM_CART: 'FETCH_PRODUCTS_FROM_CART',
}

const GIFT_CARD_SKU = '83333333'

const aggLineItems = (lineItems, isDelivery = false) => {
  const giftCardLineItems = lineItems?.filter(i => i.sku === GIFT_CARD_SKU) ?? []
  const nonGiftCardLineItems = lineItems?.filter(i => i.sku !== GIFT_CARD_SKU) ?? []
  const aggregatedItems = []
  if (!isDelivery)
    nonGiftCardLineItems?.forEach(item => {
      const itemIndex = aggregatedItems.findIndex(i => i.sku === item.sku && (item?.uid ? i.uid === item.uid : true))

      if (itemIndex > -1) {
        aggregatedItems[itemIndex].quantity += item.quantity
      } else {
        aggregatedItems.push(item)
      }
    })

  return [...aggregatedItems, ...giftCardLineItems]
}

export const useOrder = ({ order, orderId }, fetchProperties = []) => {
  const [orderData, setOrderData] = useState(order)
  const [cartItems, setCartItems] = useState([])

  const contact = orderData?.contact
  const shippingAddress = orderData?.shippingAddress

  const subtotal = orderData?.subtotal
  const total = orderData?.total

  const amountDue = orderData?.amountDue
  const totalSavings = orderData?.promotions?.totalSavings || 0

  const totalDeliveryCharge = orderData?.totalDeliveryCharge ?? 0
  const tax = orderData?.tax ?? 0
  const paymentInfo = orderData?.paymentInfo
  const isKlarnaOrder = useMemo(() => orderData?.paymentInfo?.map(payment => payment.paymentType).includes('KLN'), [
    orderData,
  ])

  const lineItems = useMemo(() => {
    let items = orderData?.lineItems?.filter(item => item.sku !== '99999925' && !item.required) ?? []
    if (!items.length) {
      items = orderData?.promotions?.lineItems?.filter(item => item.sku !== '99999925' && !item.required) ?? []
    }
    if (cartItems?.length > 0) {
      return items.map(item => ({
        ...item,
        product: cartItems?.find(cartItem => cartItem?.product?.sku === item?.sku)?.product,
      }))
    }
    return items
  }, [orderData, cartItems])

  const rtgDeliveryItems = useMemo(() => getRoomsToGoDeliveryItems(lineItems), [lineItems])
  const otherDeliveryItems = useMemo(() => getOtherDeliveryItems(lineItems), [lineItems])
  const nonRTGDeliveryItems = useMemo(() => getNonRTGDeliveryItems(lineItems), [lineItems])

  const packageSkuMap = orderData?.packageSkuMap
  const splitCalendar = orderData?.splitCalendar
  const splitDeliveryDates = orderData?.splitDeliveryDates
  const isPickup = orderData?.isPickup
  const isExpress = orderData?.isExpress
  const defaultDelivery = orderData?.defaultDelivery
  const doorwayDelivery = orderData?.doorwayDelivery
  const doorwayDeliveryCharge = orderData?.doorwayDeliveryCharge
  const additionalDirections = orderData?.additionalDirections
  const aggCartItems = aggLineItems(cartItems)
  const aggOrderLineItems = aggLineItems(orderData?.lineItems)
  const aggDeliveryItems = aggLineItems(rtgDeliveryItems)
  const aggUpsDeliveryItems = aggLineItems(nonRTGDeliveryItems.ups)
  const aggUspsDeliveryItems = aggLineItems(nonRTGDeliveryItems.usps)
  const aggVendorDeliveryItems = aggLineItems(nonRTGDeliveryItems.vendor)

  const accountExists = orderData?.accountExists
  const hasStoreCartItems = orderData?.lineItems.some(i => i.isStoreSku) && orderData?.storeCart?.lineItems?.length > 0
  const storeCartItems = !hasStoreCartItems
    ? []
    : orderData?.lineItems
        .filter(i => i.isStoreSku)
        .map(item => {
          const scItem = orderData.storeCart.lineItems.find(x => x.sku === item.sku)
          return {
            product: {
              sku: item.sku,
              title: item.title || scItem.title,
              primary_image: scItem.imageUrl ?? null,
              delivery_type: item.deliveryType,
            },
            lineItem: item.warrantyEnabled ? { warrantyPrice: item.warrantyPrice } : {},
            quantity: item.quantity,
            price: item.unitPrice,
            warrantyEnabled: item.warrantyEnabled,
            sku: item.sku,
          }
        })

  const configuredItems =
    orderData?.lineItems
      .filter(i => i.isConfigurable)
      .map(item => ({
        product: {
          sku: item.sku,
          title: item.title,
          primary_image: item.imageUrl ?? null,
          delivery_type: item.deliveryType,
        },
        isConfigurable: item.isConfigurable,
        lineItem: item.warrantyEnabled ? { warrantyPrice: item.warrantyPrice } : {},
        quantity: item.quantity,
        price: item.unitPrice,
        warrantyEnabled: item.warrantyEnabled,
      })) ?? []

  useEffect(() => {
    if (!orderData && fetchProperties.includes(FETCH_PROPERTIES.FETCH_ORDER)) {
      getOrder({ orderId }).then(data => setOrderData(data))
    }
  }, [orderId, fetchProperties, orderData])

  useEffect(() => {
    if (
      !cartItems?.length &&
      lineItems?.length > 0 &&
      fetchProperties.includes(FETCH_PROPERTIES.FETCH_PRODUCTS_FROM_CART)
    ) {
      const cartItemPromises = []
      lineItems
        .filter(i => !i.isStoreSku && !i.isConfigurable)
        .forEach(item => {
          cartItemPromises.push(
            fetchProductOrGC(item.sku, item)
              .then(product => ({
                sku: product.sku,
                uid: item.uid,
                quantity: item.quantity,
                warrantyEnabled: item.warrantyEnabled,
                product,
                price: item.sku === GIFT_CARD_SKU ? item?.unitPrice : productPrice(product),
              }))
              .catch(() => ({ ...item })),
          )
        })

      Promise.all(cartItemPromises).then(data => setCartItems([...storeCartItems, ...data, ...configuredItems]))
    }
  }, [lineItems, fetchProperties, cartItems, storeCartItems, configuredItems])

  const deliveryInfo = useMemo(() => {
    if (orderData?.deliveryInfo) {
      return {
        dates: orderData?.deliveryCalendar.map(date => ({
          readable: '',
          date,
          isPickup: false,
          isStandardDelivery: true,
        })),
        deliveryDate: orderData?.deliveryDate,
        pickup: false,
      }
    }
    return {}
  }, [orderData])

  return {
    orderData,
    subtotal,
    total,
    amountDue,
    totalSavings,
    contact,
    shippingAddress,
    isKlarnaOrder,
    lineItems,
    cartItems,
    packageSkuMap,
    rtgDeliveryItems,
    otherDeliveryItems,
    upsDeliveryItems: nonRTGDeliveryItems.ups,
    uspsDeliveryItems: nonRTGDeliveryItems.usps,
    vendorDeliveryItems: nonRTGDeliveryItems.vendor,
    aggCartItems,
    aggOrderLineItems,
    aggDeliveryItems,
    aggUpsDeliveryItems,
    aggUspsDeliveryItems,
    aggVendorDeliveryItems,
    deliveryInfo,
    splitCalendar,
    splitDeliveryDates,
    totalDeliveryCharge,
    tax,
    paymentInfo,
    isPickup,
    isExpress,
    defaultDelivery,
    doorwayDelivery,
    doorwayDeliveryCharge,
    additionalDirections,
    accountExists,
  }
}

useOrder.propTypes = {
  order: any,
  orderId: string,
}
